var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-2"},[_vm._t("before"),_c('v-card',[_c('div',{staticClass:"d-flex"},[_c('div',[_c('v-card-title',[_vm._v(_vm._s(_vm.dish.dishName)+" ")]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.dish.dishDesc))])],1),_c('v-spacer'),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"width":"400px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFormat),callback:function ($$v) {_vm.dateFormat=$$v},expression:"dateFormat"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"locale":"de-DE","scrollable":"","width":"400"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)]),_c('v-sheet',{staticClass:"my-4",staticStyle:{"overflow-y":"scroll"},attrs:{"height":"300px"}},[(_vm.selectSKU === -1)?[_vm._l((_vm.computedOption),function(item){return [_c('h4',{key:'mod2head'+item.id},[_vm._v(_vm._s(("" + (item.name))))]),_c('v-sheet',{key:'mod2'+item.id,staticClass:"pa-1"},[_c('v-item-group',{attrs:{"mandatory":item.required==='1',"multiple":item.multiSelect==='1',"active-class":"warning"},model:{value:(_vm.mod[item.id]),callback:function ($$v) {_vm.$set(_vm.mod, item.id, $$v)},expression:"mod[item.id]"}},_vm._l((item.select),function(s,index){return _c('v-item',{key:'mod111'+index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{staticClass:"ma-1",attrs:{"ripple":false,"small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(s.text)+" ")])]}}],null,true)})}),1)],1)]})]:[_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.SKUSelect),callback:function ($$v) {_vm.SKUSelect=$$v},expression:"SKUSelect"}},[_c('div',{staticClass:"mt-4",staticStyle:{"display":"grid","grid-template-columns":"repeat(4,minmax(0,1fr))","grid-gap":"8px","width":"100%"}},_vm._l((_vm.SKUList),function(i){return _c('v-item',{key:i.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"color":"#eee","elevation":"0","outlined":""},on:{"click":function($event){return toggle()}}},[_c('v-responsive',{attrs:{"aspect-ratio":1}},[_c('div',{staticClass:"d-flex align-center justify-center flex-column pa-2",staticStyle:{"height":"100%","position":"relative"}},[(active)?_c('v-icon',{staticStyle:{"position":"absolute","bottom":"0px"},attrs:{"color":"primary","size":"36"}},[_vm._v(" mdi-check-circle ")]):_vm._e(),_c('div',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(i.name)+" ")]),_c('div',{staticClass:"mt-2"},[_vm._v("配料数量: "+_vm._s(i.attrCount))])],1)])],1)]}}],null,true)})}),1)])]],2),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","color":"orange darken-2","outlined":"","text":""},on:{"click":_vm.cancel}},[_vm._v("Zurück")])],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.selectSKU === -1)?[_c('v-btn',{attrs:{"block":"","color":"orange darken-2","dark":""},on:{"click":_vm.nextStep}},[_vm._v("下一步")])]:[_c('v-btn',{attrs:{"block":"","color":"orange darken-2","dark":""},on:{"click":_vm.printLabel}},[_vm._v("ok")])]],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }