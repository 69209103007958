<template>
  <v-app>
    <v-app-bar app color="#f57c00" dark>
      <v-chip-group v-model="activeCT" mandatory>
        <template v-for="(category) in notEmptyCategory">
          <v-chip :key="'nav'+category.id" @click="gotoCategory(category)">
            {{ category.langs[0].name }}
          </v-chip>
        </template>
      </v-chip-group>
    </v-app-bar>
    <v-main style="background: #fefefe">

      <v-container v-cloak style="max-width: 672px">
        <template  v-for="category in notEmptyCategory">
          <v-col :id="'category'+category.id" :key="category.id" v-intersect="handle">
            <h1>{{ category.langs[0].name }}</h1>
          </v-col>
          <template v-for="dish in category.dishes">
            <v-col :key="dish.code+'m'+dish.haveMod"
                   class="pa-1" cols="12">
              <v-hover #default="{hover}" close-delay="100">
                <v-card :id="'dishCard'+dish.code" :color="hover?'#eef7fa':''"
                        elevation="0"
                        outlined>
                  <v-card-title class="d-flex">
                    <div style="max-width:80%; height:100%">
                          <span style="word-break: break-word;
                     font-family: 'IBM Plex Serif', serif;
">{{ dish.code }}. {{
                              dish.dishName ?
                                  dish.dishName
                                      .replaceAll('[/chili/]', '🌶️')
                                      .replaceAll('[/gutou/]', '🦴') : ''
                            }} {{ dish.haveMod > 0 ? '*' : '' }}</span>
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn color="orange lighten-1" icon large
                           @click="dishClick(dish)">
                      <template v-if="!dish.modShow">
                        <v-icon>mdi-plus-circle</v-icon>
                      </template>
                      <template v-else>
                        <v-icon>mdi-close-circle</v-icon>
                      </template>
                    </v-btn>
                  </v-card-title>
                </v-card>
              </v-hover>
            </v-col>
          </template>
        </template>

      </v-container>
    </v-main>
    <v-bottom-sheet v-model="dishModShow" inset>
      <DishModification
          :attr-list="attrList"
          :dish="editDish"
          :mod-dialog="dishModShow"
          :options="editDish.modInfo"
          @close="closeDialog()"
          @modification-submit="submitModification($event,editDish)"
          @modification-cancel="toggleMod(null)"/>
    </v-bottom-sheet>
  </v-app>
</template>

<script>
import DishModification from '@/components/DishModification'
// import { mergeSpan } from '../common/mergeSpanJH'
import {IKUtils} from 'innerken-js-utils'
import GlobalConfig from '../GlobalSettingManager'
import {getCategory, getDishes, getHasIngredientAttrList, getHasSKUIngredientDishList} from '@/api/api'
import hillo from 'hillo'
import {uniqBy} from "lodash-es";

export default {
  name: 'Menu',
  components: {
    DishModification
  },
  data: function () {
    return {
      attrList: [],
      Config: GlobalConfig,
      editDish: {modInfo: []},
      activeCT: null,
      cartShow: null,
      dialog: false,
      dishesInfo: [],
      categoryInfo: [],
      dish: {},
      loading: true,
      records: [],
      show: false,
      dishModShow: null,
      isAbholung: false,
      cartColor: 'orange darken-2',
      searchDish: null,
      hideSearchbar: 0,
      GlobalConfig
    }
  },
  computed: {
    notEmptyCategory(){
      console.log(this.categoryInfo.map(ct=>ct?.dishes?.length>0), 'test')
      return this.categoryInfo.filter(ct=>ct?.dishes?.length>0)
    },
    dishFiltered () {
      return IKUtils.deepCopy(this.dishesInfo).map(dct => {
        dct.categories = dct.categories.map(c => {
          c.dishes = c.dishes.filter(d => {
                return !this.searchDish ||
                    d.code.toLowerCase().includes(this.searchDish.toLowerCase()) ||
                    d.dishName.toLowerCase().includes(this.searchDish.toLowerCase())
              }
          )
          return c
        })
        dct.categories = dct.categories.filter(c => c.dishes.length > 0)
        return dct
      })
    }
  },
  methods: {
    handle (
        entries
    ) {
      if (entries[0].isIntersecting) {
        const categoryId = entries[0].target.id.substr(8)
        let index = 0
        for (const c of this.categoryInfo) {
          if (parseInt(c.id) === parseInt(categoryId)) {
            break
          }
          index++
        }
        this.activeCT = index
      }
    },
    gotoCategory: function (category) {
      this.$vuetify.goTo(`#category${category.id}`)
    },
    changeColorTemporary () {
      this.cartColor = 'amber darken-2'
      setTimeout(() => {
        this.cartColor = 'orange darken-2'
      }, 100)
    },
    dishClick (dish) {
      if (!(dish.haveMod > 0)) {
        this.addDish(dish)
      } else {
        this.toggleMod(dish)
      }
    },
    close () {
      this.dialog = false
    },
    closeDialog(){
      this.dishModShow = false
      this.editDish = {modInfo: []}
    },
    toggleMod (dish) {
      this.dishModShow = !this.dishModShow
      if (dish != null) {
        this.editDish = IKUtils.deepCopy(dish)
      } else {
        this.editDish = {modInfo: []}
      }
    },
    submitModification: function (_mod, dish) {
      const mod = _mod.mod
      const note = _mod.note ?? ''
      console.log(mod, note, _mod)
      const apply = []
      for (const i of dish.modInfo) {
        const item = {}
        item.groupId = i.id
        item.selectId = i.selectValue.filter((s, index) => {
          return [(mod[i.id] ?? [])].flat().includes(index)
        })
        if (i.required === '1' && item.selectId === '') {
          item.selectId = i.selectValue[0]
        }
        apply.push(item)
      }
      // dish.apply = apply
      // dish.note = note
      // this.addDishWithMod(dish, 1)
      // this.toggleMod(null)
    },
    addDish: function (dish, count = 1) {
      this.updateCart(dish, count)
    },
    addDishWithMod: function (dish, count) {
      this.updateCart(dish, count)
    },
    //  打印
    async directPrintKitchenBon (dishes) {
      return await hillo.post('Complex.php?op=directPrintKitchenBon', {dishes, tableId: 1})
    },
    updateCart: function (dish, count) {
      if (count > 0) {
        IKUtils.toast()
      }
      dish.count = count
      this.directPrintKitchenBon([dish])
    },
  },
  mounted: async function () {
    const dishList = (await getHasSKUIngredientDishList())
    const res = uniqBy(dishList, 'dishesCategoryId').map(it => it.dishesCategoryId)
    this.attrList = (await getHasIngredientAttrList())
    const categoryList = await getCategory()
    const dishInfo = (await getDishes())
    this.dishesInfo = dishInfo.filter(it => dishList.map(it => it.id).includes(it.dishId))
    this.categoryInfo = categoryList.filter(it => res.includes(it.id)).map(c => {
      c.dishes = this.dishesInfo.filter(d => d.categoryId === c.id)
      return c
    })
    this.loading = false
  }
}
</script>

<style scoped>

.categoryContainer {
  margin-top: 34px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 12px 0;
}

.categoryItem {
  font-size: 30px;
  font-weight: 900;
  width: fit-content;
  border-bottom: 1px solid black;
}

.categoryDesc {
  margin-top: 12px;
  font-size: 18px;
}

.text-wrapper {
  white-space: pre-wrap;
}

</style>
