import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import GlobalConfig, { loadConfig } from '@/GlobalSettingManager'

Vue.config.productionTip = false
Vue.filter('priceDisplay',
  function (price) {
    return parseFloat(price).toFixed(2).replace('.', ',')
  }
)
Vue.filter('cut',
  function (str, cutLength = 24) {
    if (str.length > cutLength) {
      return str.substr(0, cutLength) + '..'
    } else {
      return str
    }
  }
)

async function initial () {
  await loadConfig()
  console.log(GlobalConfig, 'GlobalConfig')
  new Vue({

    vuetify,

    render: h => h(App)
  }).$mount('#app')
}

initial()
