import hillo from 'hillo'

export async function getDishes () {
  return (await hillo.get('Dishes.php', { lang: 'de' })).content
}

export async function getCategory(){
  return (await hillo.get('Category.php',{lang:'de'})).content
}

export async function getHasSKUIngredientDishList () {
  return (await hillo.get('/LabelPrint.php?op=getDishHasIngredientAndHasSku&lang=DE', {})).content
}

export async function getHasIngredientAttrList () {
  return (await hillo.get('/LabelPrint.php?op=getAttrHasIngredient', {})).content
}

export async function getSKUList (id) {
  return (await hillo.get('/LabelPrint.php?op=getLabelSkuList&dishIds=' + id, {
  })).content
}

export async function getAttribute(){
  return (await hillo.get('Category.php',{
    op: 'showAttribute',
    lang:'de'
  })).content
}

export async function getPrint (item, date) {
  return (await hillo.post('/LabelPrint.php?op=printLabel', {
    ...item,
    dynamics: JSON.stringify(date),
  }))
}