<template>
  <v-card class="pa-2">
    <slot name="before"></slot>
    <v-card>
      <div class="d-flex">
        <div>
          <v-card-title>{{ dish.dishName }}
          </v-card-title>
          <v-card-subtitle>{{ dish.dishDesc }}</v-card-subtitle>
        </div>
        <v-spacer/>
        <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            width="400px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateFormat"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="date"
              locale="de-DE"
              scrollable
              width="400"
          >
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
                color="primary"
                text
                @click="$refs.dialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
    </v-card>
    <v-sheet class="my-4" height="300px" style="overflow-y: scroll">
      <template v-if="selectSKU === -1">
      <template v-for="item in computedOption">
        <h4 :key="'mod2head'+item.id">{{ `${item.name}` }}</h4>
        <v-sheet :key="'mod2'+item.id" class="pa-1">
          <v-item-group
              v-model="mod[item.id]"
              :mandatory="item.required==='1'"
              :multiple="item.multiSelect==='1'"
              active-class="warning">
            <v-item v-for="(s,index) in item.select" :key="'mod111'+index" v-slot="{active,toggle}">
              <v-chip :ripple="false" class="ma-1" small @click="toggle">
                {{ s.text }}
              </v-chip>
            </v-item>

          </v-item-group>
        </v-sheet>
      </template>
      </template>
      <template v-else>
        <v-item-group
            v-model="SKUSelect"
            mandatory
        >
          <div
              class="mt-4"
              style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));grid-gap: 8px;width: 100%"
          >
            <v-item
                v-for="i in SKUList"
                :key="i.id"
                v-slot="{ active, toggle }"
            >
              <v-card
                  class="d-flex align-center justify-center"
                  color="#eee"
                  elevation="0"
                  outlined
                  @click="toggle()"
              >
                <v-responsive
                    :aspect-ratio="1"
                >
                  <div
                      class="d-flex align-center justify-center flex-column pa-2"
                      style="height: 100%;position: relative"
                  >
                    <v-icon
                        v-if="active"
                        color="primary"
                        size="36"
                        style="position: absolute;bottom: 0px;"
                    >
                      mdi-check-circle
                    </v-icon>
                    <div class="text-center font-weight-bold">
                      {{ i.name }}
                    </div>
                    <div class="mt-2">配料数量: {{i.attrCount}}</div>
                  </div>
                </v-responsive>
              </v-card>
            </v-item>
          </div>
        </v-item-group>
      </template>
    </v-sheet>
    <v-row>
      <v-col cols="6">
        <v-btn block color="orange darken-2" outlined text @click="cancel">Zurück</v-btn>
      </v-col>
      <v-col cols="6">
        <template v-if="selectSKU === -1">
          <v-btn block color="orange darken-2" dark @click="nextStep">下一步</v-btn>
        </template>
        <template v-else>
          <v-btn block color="orange darken-2" dark @click="printLabel">ok</v-btn>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {getPrint, getSKUList} from "@/api/api";
import dayjs from "dayjs";

export default {
  name: 'DishModification',
  props: {
    dish: {},
    attrList: {},
    modDialog: {},
    options: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      modal: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      SKUSelect: null,
      SKUList: [],
      selectSKU: -1,
      hasIngredientList: [],
      selected: false,
      mod: {},
      note: ''
    }
  },
  watch: {
    modDialog(val){
      if(!val){
        this.selectSKU = -1
      }
    }
  },
  computed: {
    dateFormat: function () {
      return dayjs(this.date).format('DD.MM.YYYY')
    },
    computedOption: function () {
      const realModInfo = []
      this.options.forEach(item => {
        item.select = []
        if (!Array.isArray(item.selectName)) {
          item.selectName = item.selectName.split(',')
          item.selectValue = item.selectValue.split(',')
          item.priceInfo = item.priceInfo.split(',')
        }

        item.selectName.forEach((name, index) => {
          item.select.push({
            text: `${name}`,
            value: item.selectValue[index],
            price: parseFloat(item.priceInfo[index]),
            priceInfo: parseFloat(item.priceInfo[index]) === 0 ? '' : `(€${parseFloat(item.priceInfo[index]).toFixed(2)})`
          })
        })
        realModInfo.push(item)
      })
      return realModInfo
    },
    realMod: function () {
      const realMod = []
      for (const groupId in this.mod) {
        for (const selectIndex of [this.mod[groupId]].flat()) {
          realMod.push({
            groupId,
            selectIndex
          })
        }
      }
      return realMod
    },
    addPrice: function () {
      return this.realMod.reduce((total, i) => {
        total += this.findModItemUseGroupIdAndIndex(i.groupId, i.selectIndex)?.price ?? 0
        return total
      }, 0) ?? 0
    },
    realPrice: function () {
      return parseFloat(this.dish.price) + parseFloat(this.addPrice)
    }
  },
  methods: {
    findModItemUseGroupIdAndIndex (groupId, index) {
      return this.computedOption.find(g => parseInt(g.id) === parseInt(groupId)).select[parseInt(index)]
    },
    cancel () {
      this.mod = {}
      this.note = ''
      this.$emit('modification-cancel')
    },
    async nextStep(){
      this.selectSKU = 1
      this.SKUList = await getSKUList(this.dish.dishId)
    },
    async printLabel() {
      const arr = []
      for (const i of this.dish.modInfo) {
        const item = {}
        item.selectId = i.selectValue.filter((s, index) => {
          return [(this.mod[i.id] ?? [])].flat().includes(index)
        })
        if (i.required === '1' && item.selectId === '') {
          item.selectId = i.selectValue[0]
        }
        arr.push(item.selectId)
      }
      const attrIdList = arr.join(',').split(',').filter(it => {
        return it && it.trim()
      }).join(',')
      await getPrint({
        dishId: this.dish.dishId,
        attrIds: attrIdList,
        labelSkuId: this.SKUList[this.SKUSelect].id,
      },{packingDate:this.dateFormat})
      this.cancel()
    }
  },
}
</script>

<style scoped>
</style>
