import { loadBaseConfig } from 'aaden-base-model/lib/Models/GlobalSettings'

const defaultConfig = require('@/assets/config.json')
const GlobalConfig = Object.assign({}, defaultConfig)

export async function loadConfig () {
  Object.assign(GlobalConfig, await loadBaseConfig(GlobalConfig))
}

export default GlobalConfig
